import React from "react";
import { Link } from "react-router-dom";

export default function Hero() {
  return (
    <>
      <div className="">
        <div className="relative isolate pt-14">
        <svg
        className="absolute inset-0 -z-10 h-full w-full stroke-gray-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
        aria-hidden="true"
      >
        <defs>
          <pattern
            id="0787a7c5-978c-4f66-83c7-11c213f99cb7"
            width={200}
            height={200}
            x="50%"
            y={-1}
            patternUnits="userSpaceOnUse"
          >
            <path d="M.5 200V.5H200" fill="none" />
          </pattern>
        </defs>
        <rect width="100%" height="100%" strokeWidth={0} fill="url(#0787a7c5-978c-4f66-83c7-11c213f99cb7)" />
      </svg>
          <div className="py-24 sm:py-32 lg:pb-40">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
              <div className="mx-auto max-w-3xl text-center">
                <h1 className="text-4xl font-bold tracking-tight lg:leading-[70px] text-gray-950 sm:text-6xl">
                  Financial engineering meets with Blockchain and AI.
                </h1>
                <p className="mt-6 text-lg leading-8 text-gray-500">
                  FINENG brings the power of blockchain and AI to everyone, from
                  seasoned investors to those just starting out. We are empowering your
                  financial future with web3 based tools.
                </p>
                <div className="mt-10 flex items-center justify-center gap-x-6">
                 

                   
                  <Link
                    to="/roadmap"
                    className="text-sm font-normal uppercase border border-black text-black  px-6 py-4 rounded-full leading-6 "
                  >
                   Get started
                  </Link>
                  <Link
                    to="/tokenomics"
                    className="bg-gradient-to-r  from-orange-600 to-blue-500 rounded-full px-6 py-4 text-sm font-normal uppercase text-white shadow-sm"
                  >
                     Tokenomics 
                  </Link>
                </div>
                <h1 className="font-bold mt-10 text-transparent bg-clip-text bg-gradient-to-r from-red-500 via-blue-500 to-purple-600 text-4xl">Total Supply: 150,000,000,000. $FINENG</h1>
                <h1 className="font-bold mt-5 text-red-500 text-4xl">Pre listing Price $0.0005</h1>
              </div>
            </div>
            <div className=" mt-20">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
              <h2 className="text-center text-lg font-semibold text-gray-950 leading-8">
                Built to run on the leading blockchain networks
              </h2>
              <div className="mx-auto mt-10 tex grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-4">
                <img
                  alt="BTC"
                  src="/images/partners/bitcoin-btc-logo-full.svg"
                  width={158}
                  height={48}
                  className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
                />
               
               
                   <img
                  alt="ETH"
                  src="/images/partners/ethereum-eth-logo-full-horizontal.svg"
                  width={158}
                  height={48}
                  className="col-span-2 max-h-12 w-full object-contain sm:col-start-2 lg:col-span-1"
                />
                 <img
                  alt="Ton"
                  src="/images/partners/tron-trx-logo-full.svg"
                  width={158}
                  height={48}
                  className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
                />
                
                <img
                  alt="BNB"
                  src="/images/partners/coinbase.svg"
                  width={158}
                  height={48}
                  className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
                />
               
              
               
              </div>
            </div>
          </div>
          </div>

       
        </div>
      </div>
    </>
  );
}
