const navigation = {
  learn: [
    { name: "Resources", href: "https://www.youtube.com/@financialengineering2024" },
    { name: 'Whitepaper', href: '/whitepaper.pdf' },
  ],
  support: [
    { name: "Annoucement", href: "https://t.me/fineng_Announcement" },
    { name: "Facebook", href: "https://www.facebook.com/Finengservice" },
    { name: "Telegram", href: "https://t.me/fineng_English" },
  ],
  company: [
    { name: 'Tokenomics', href: '/tokenomics' },
    { name: "FINENG Mining App", href: "#" },
    { name: "Leadersboard", href: "/leadersboard" },
    { name: "Roadmap", href: "/roadmap" },
    
  ],
  legal: [
    { name: "Privacy", href: "#" },
    { name: "Terms", href: "#" },
  ],
  social: [
    {
      name: "Facebook",
      href: "https://www.facebook.com/Finengservice",
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
   
    {
      name: "X",
      href: "https://x.com/Fineng_chain1?t=bCBYw3ZAVeIuwxdSqiVyFA&s=09",
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path d="M13.6823 10.6218L20.2391 3H18.6854L12.9921 9.61788L8.44486 3H3.2002L10.0765 13.0074L3.2002 21H4.75404L10.7663 14.0113L15.5685 21H20.8131L13.6819 10.6218H13.6823ZM11.5541 13.0956L10.8574 12.0991L5.31391 4.16971H7.70053L12.1742 10.5689L12.8709 11.5655L18.6861 19.8835H16.2995L11.5541 13.096V13.0956Z" />
        </svg>
      ),
    },
   
    {
      name: "Telegram",
      href: "https://t.me/fineng_Announcement",
      icon: (props) => (
        <svg
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 128 128" // Adjusted to match the path data coordinates
          {...props}
        >
          <path d="M64 10.848c-29.355 0-53.152 23.797-53.152 53.152S34.645 117.152 64 117.152 117.152 93.355 117.152 64 93.355 10.848 64 10.848zM91.533 34.72c2.16-.079 3.794 1.876 2.918 5.388L83.92 89.742c-.735 3.527-2.868 4.371-5.82 2.742L62.055 80.63c-3.769 3.664-7.145 6.946-7.713 7.502-.894.873-1.606 1.605-3.211 1.605-2.08 0-1.726-.787-2.443-2.767l-5.903-18.094-15.426-4.816c-3.426-1.05-3.451-3.404.77-5.098l61.85-23.856a4.14 4.14 0 011.554-.384zm-9.81 10.556c-.465-.006-1.098.16-1.748.555L45.207 67.768l5.857 17.962 1.16-12.363 29.772-26.861c.817-.725.502-1.218-.273-1.229z"></path>
        </svg>
      ),
    },
    
    {
      name: "YouTube",
      href: "https://www.youtube.com/@financialengineering2024",
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M19.812 5.418c.861.23 1.538.907 1.768 1.768C21.998 8.746 22 12 22 12s0 3.255-.418 4.814a2.504 2.504 0 0 1-1.768 1.768c-1.56.419-7.814.419-7.814.419s-6.255 0-7.814-.419a2.505 2.505 0 0 1-1.768-1.768C2 15.255 2 12 2 12s0-3.255.417-4.814a2.507 2.507 0 0 1 1.768-1.768C5.744 5 11.998 5 11.998 5s6.255 0 7.814.418ZM15.194 12 10 15V9l5.194 3Z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
  ],
};

export default function Footer() {
  return (
    <footer
      aria-labelledby="footer-heading"
      className="bg-gradient-to-r  from-[#121212] to-[#121237] "
    >
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="mx-auto max-w-7xl px-6 pb-8 pt-16 sm:pt-24 lg:px-8 lg:pt-32">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="space-y-8">
            <div>
              <img
                alt="Company name"
                src="/images/logo.png"
                className="h-40 inline-flex"
              />
            </div>

            <p className="text-sm leading-6 text-gray-300">
              Bridging the Gap Between Traditional and Digital Finance. 
            </p>
            <div className="flex space-x-6">
              {navigation.social.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  className="text-gray-500 hover:text-gray-400"
                >
                  <span className="sr-only">{item.name}</span>
                  <item.icon aria-hidden="true" className="h-6 w-6" />
                </a>
              ))}
            </div>
            <p className="text-sm leading-6 text-gray-300">
              support@finengservice.com
            </p>
          </div>
          <div className="mt-16 grid grid-cols-2 gap-8 xl:col-span-2 xl:mt-0">
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold leading-6 text-white">
                  Learn
                </h3>
                <ul role="list" className="mt-6 space-y-4">
                  {navigation.learn.map((item) => (
                    <li key={item.name}>
                      <a
                        href={item.href}
                        className="text-sm leading-6 text-gray-300 hover:text-white"
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-10 md:mt-0">
                <h3 className="text-sm font-semibold leading-6 text-white">
                  Community
                </h3>
                <ul role="list" className="mt-6 space-y-4">
                  {navigation.support.map((item) => (
                    <li key={item.name}>
                      <a
                        href={item.href}
                        className="text-sm leading-6 text-gray-300 hover:text-white"
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold leading-6 text-white">
                FINENG
                </h3>
                <ul role="list" className="mt-6 space-y-4">
                  {navigation.company.map((item) => (
                    <li key={item.name}>
                      <a
                        href={item.href}
                        className="text-sm leading-6 text-gray-300 hover:text-white"
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-10 md:mt-0">
                <h3 className="text-sm font-semibold leading-6 text-white">
                Legal
                </h3>
                <ul role="list" className="mt-6 space-y-4">
                  {navigation.legal.map((item) => (
                    <li key={item.name}>
                      <a
                        href={item.href}
                        className="text-sm leading-6 text-gray-300 hover:text-white"
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-16 border-t border-white/10 pt-8 sm:mt-20 lg:mt-24">
          <p className="text-xs leading-5 text-gray-400">
            &copy; 2024 Fineng Foundation. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
}
