import React from 'react';
import { PieChart, Pie, Cell, Tooltip, Legend } from 'recharts';

const data = [
  { name: 'Pre-Listing Airdrop (30%)', value: 45000000000 },
  { name: 'Post-Launch Airdrop & Staking Rewards (30%)', value: 45000000000 },
  { name: 'Reserved Supply (30%)', value: 45000000000 },
  { name: 'Launchpool (10%)', value: 15000000000 },
];

const COLORS = ['#4CAF50', '#FF9800', '#F44336', '#2196F3'];

const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
  const RADIAN = Math.PI / 180;
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? 'start' : 'end'}
      dominantBaseline="central"
      className="text-sm sm:text-base font-bold"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const Tokenomics = () => {
  return (
    <div className="bg-gradient-to-b from-gray-900 via-gray-800 to-gray-900 text-white py-20 px-6 sm:px-10 md:px-16 lg:px-20">
      <div className="max-w-7xl mx-auto">
        <h1 className="text-5xl md:text-6xl font-bold mb-12 text-center text-transparent bg-clip-text bg-gradient-to-r from-green-400 via-blue-500 to-purple-600">
          FINENG Tokenomics Overview
        </h1>

        <div className="flex flex-col md:flex-row items-center justify-between mb-16">
          <div className="md:w-1/2 mb-10 md:mb-0 flex justify-center">
          <PieChart width={400} height={400}>
    <Pie
      data={data}
      cx="50%"
      cy="50%"
      innerRadius={80}
      outerRadius={160}
      fill="#8884d8"
      paddingAngle={5}
      dataKey="value"
      labelLine={false}
    >
      {data.map((entry, index) => (
        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
      ))}
    </Pie>
    <Tooltip />
    <Legend
      verticalAlign="bottom"
      align="center"
      layout="horizontal"
      wrapperStyle={{ paddingTop: '20px' }}
      itemStyle={{ marginRight: '70px' }} // Add space between legend items
    />
  </PieChart>
          </div>

          <div className="md:w-1/2 space-y-8 text-gray-300">
            <p className="text-xl"><strong>Total Supply:</strong> The <strong>$FINENG</strong> token has a total supply of <strong>150 Billion</strong> (<strong>150B</strong>) tokens. This supply is strategically allocated to ensure community growth, project sustainability, and incentives for early adopters.</p>

            <p className="text-xl"><strong>Community Airdrop (60%):</strong> 60% of the total supply, equivalent to <strong>90 Billion</strong> tokens, will be distributed to the community through an airdrop, divided into two phases:</p>
            <ul className=" list-inside ml-6">
              <li><strong>Pre-Listing Airdrop (30% of Total Supply):</strong> Half of the airdrop tokens will be distributed before the project is listed on centralized exchanges (CEX).</li>
              <li><strong>Post-Launch Airdrop and Staking Rewards (30% of Total Supply):</strong> The remaining airdrop tokens will be distributed after the listing, ensuring constant engagement and rewards for the community.</li>
            </ul>

            <p className="text-xl"><strong>Reserved Supply and Launchpool (40%):</strong> The remaining 40% of the total supply is allocated as follows:</p>
            <ul className=" list-inside ml-6">
              <li><strong>Reserved (30% of Total Supply):</strong> Set aside for future strategic initiatives, ensuring the project’s long-term growth and adaptability.</li>
              <li><strong>Launchpool (10% of Total Supply):</strong> Dedicated to rewarding early participants through launchpool activities, fostering early momentum for the project.</li>
            </ul>

            <p className="text-xl">This tokenomics structure is designed to prioritize community involvement, reward active participation, and support the sustainable growth of the FINENG ecosystem.</p>
          </div>
        </div>

        <div className="subscribe rounded-lg p-8 shadow-lg space-y-8 ">
          <h2 className="text-3xl font-bold mb-6 text-center text-white">Community Engagement & Reward Mechanisms</h2>
          <p className="text-gray-300">To drive active participation and reward community members, the following tasks and activities will be introduced:</p>

          <ul className="list-decimal list-inside ml-6 space-y-4 text-gray-300">
            <li><strong>Daily Login Rewards:</strong> Users who log in daily without interruption will earn tokens, promoting consistent platform engagement.</li>
            <li><strong>Referral Program:</strong> Users can earn tokens by inviting others to join the FINENG project using their unique referral links. Both the referrer and the referred individual will be rewarded once the new user completes all required social tasks.</li>
            <li><strong>Share-to-Earn Program (S2E):</strong> Users will earn tokens by sharing $FINENG Educational Content on their WhatsApp status, Facebook reels, Instagram reels, Telegram status, TikTok, etc. A specified amount of tokens will be awarded for each successful share.</li>
            <li><strong>Watch-to-Earn Program (W2E):</strong> Users (Gold) can double their tokens earnings by watching Educational Content on our YouTube channel. This daily task encourages regular interaction with FINENG content.</li>
            <li><strong>Social Media Engagement:</strong> Users can earn tokens by following $FINENG on platforms such as Instagram, Twitter (X), Facebook, YouTube, Discord, and Telegram. This one-time reward will be credited once users have followed all specified accounts.</li>
            <li><strong>Bot Mining:</strong> A bot mining (Platinum) feature will allow subscribers to earn more tokens daily. This earning mechanism provides an additional incentive including free Financial Engineers Unique NFT and other benefits.</li>
            <li><strong>Registration Bonus:</strong> New users will receive a welcome bonus/reward upon successful registration, whether they were referred by an existing user or not.</li>
          </ul>

          <h3 className="text-2xl font-bold mt-8">Earnings and Listing Timeline</h3>
          <p className="text-gray-300">The total earning period will conclude on the last day of December 2024. However, $FINENG will be listed on multiple top-tier centralized exchanges in January 2025.</p>
          <h3 className="text-2xl font-bold mt-8">Future Development</h3>
          <p className="text-gray-300">In the near future, FINENG plans to launch its own USDT pairing. This will allow users to swap FINENG tokens directly to USDT.</p>

          <h3 className="text-2xl font-bold mt-8">Important Notice</h3>
          <p className="text-gray-300">Our official smart contract address has not been released yet. Be aware that any address claiming to represent FINENG at this stage is fraudulent.</p>

          <h3 className="text-2xl font-bold mt-8">Pre Market Listing Price</h3>
          <p className="text-gray-300">FINENG will be listed at an initial price of $0.0015 per token.</p>
        </div>
      </div>
    </div>
  );
};

export default Tokenomics;
