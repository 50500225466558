import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Cta from "../components/Cta";
import Faq from "../components/Faqs";
import HeroTokenomics from "../components/Tokenomics/Hero";
import Tokenomics from "../components/Tokenomics/Tokenomics";

function TokenomicPage() {
  return (
    <div className="">
      <Navbar />
      <HeroTokenomics />
      <Tokenomics/>   
      <Faq/>
      <Cta/>
      <Footer />
    </div>
  );
}

export default TokenomicPage;
