import { Link } from "react-router-dom";

export default function Features() {
  return (
    <div className="bg-[#FBFBFB] py-16">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
          <h2 className="text-3xl font-bold tracking-tight text-gray-950  sm:text-4xl">
            Our mission
          </h2>
          <div className="mt-6 flex flex-col gap-x-8 gap-y-20 lg:flex-row">
            <div className="lg:w-full lg:max-w-2xl lg:flex-auto">
              <p className="text-xl leading-8 text-gray-950 ">
                Our mission is to bridge the gap between traditional financial
                instruments and the emerging world of cryptocurrency. We aim to
                educate users on the principles of financial engineering,
                enabling them to leverage these principles for profitable and
                sustainable investments.
              </p>
              <p className="mt-10 max-w-xl text-base text-gray-950 leading-7 ">
                Through our platform, users can access valuable resources,
                including YouTube content and social media posts, to learn how
                to navigate the complex financial landscape effectively.
              </p>
            </div>
            <div className="lg:flex lg:flex-auto lg:justify-center">
              <img className="rounded-md" src="/images/mble.jpeg" alt="lmark"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
