import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Cta from "../components/Cta";
import Faq from "../components/Faqs";
import HeroRoadmap from "../components/Roadmap/Hero";
import { Roadmap } from "../components/Roadmap/Roadmap";

function RoadmapPage() {
  return (
    <div className="">
      <Navbar />
     <HeroRoadmap/>
     <Roadmap/>
      <Faq/>
      <Cta/>
      <Footer />
    </div>
  );
}

export default RoadmapPage;
