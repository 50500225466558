import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Cta from "../components/Cta";
import Faq from "../components/Faqs";
import HeroLeaderboard from "../components/Leadersboard/Hero";
import LeadersBoard from "../components/Leadersboard/LeadersBoard";

function LeadersboardPage() {
  return (
    <div className="">
      <Navbar />
      <HeroLeaderboard />
      <LeadersBoard/>   
      <Faq/>
      <Cta/>
      <Footer />
    </div>
  );
}

export default LeadersboardPage;
