import { Link } from "react-router-dom";

export default function Cta() {
    return (
      <div className="relative isolate overflow-hidden bg-[#87CEEB] subscribe mx-auto max-w-7xl mb-10 rounded-xl">
        <div className="px-6 py-24 sm:px-6 sm:py-32 lg:px-8">
          <div className="mx-auto max-w-4xl text-center">
            <h2 className="font-bold lg:leading-1 sm:leading-none text-gray-50 lg:text-6xl sm:text-4xl">
            It's time to join the thousands of web3 creators, artists, and developers learning Financial Engineering.
            </h2>
          
            <div className="mt-10 flex items-center justify-center gap-x-6">
            <Link
                    to="https://t.me/fineng_bot/"
                    className="bg-gradient-to-r  from-orange-600 to-blue-500 rounded-full px-6 py-4 text-sm font-normal uppercase text-white shadow-sm"
                  >
                    Start Mining (FINENG)
                  </Link>
            </div>
          </div>
        </div>
        <svg
          viewBox="0 0 1024 1024"
          aria-hidden="true"
          className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-x-1/2 [mask-image:radial-gradient(closest-side,white,transparent)]"
        >
          <circle r={512} cx={512} cy={512} fill="url(#8d958450-c69f-4251-94bc-4e091a323369)" fillOpacity="0.7" />
          <defs>
            <radialGradient id="8d958450-c69f-4251-94bc-4e091a323369">
              <stop stopColor="#87CEEB" />
              <stop offset={1} stopColor="#E935C1" />
            </radialGradient>
          </defs>
        </svg>
      </div>
    )
  }
  
