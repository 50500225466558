import React from "react";
import Navbar from "../components/Navbar";
import Hero from "../components/Hero";
import Features from "../components/Features";
import Footer from "../components/Footer";
import Cta from "../components/Cta";
import Purpose from "../components/Purpose";
import Faq from "../components/Faqs";

function HomePage() {
  return (
    <div className="">
      <Navbar />
      <Hero />
      <Features />
      <Purpose/>
      <Faq/>
      <Cta/>
      <Footer />
    </div>
  );
}

export default HomePage;
