"use client";
import React, { useState, useEffect } from "react";
import numbro from "numbro";

// Dummy data for the leaderboard
const dummyLeaders = [
  {
    username: "Alice",
    value: 5000,
    challenge: 1500,
    avatar: "avatar1",
  },
  {
    username: "Bob",
    value: 4000,
    challenge: 1200,
    avatar: "avatar2",
  },
  {
    username: "Charlie",
    value: 3500,
    challenge: 1000,
    avatar: "avatar3",
  },
  {
    username: "Diana",
    value: 3000,
    challenge: 900,
    avatar: "avatar4",
  },
  {
    username: "Edward",
    value: 2500,
    challenge: 800,
    avatar: "avatar5",
  },
  // Add more dummy leaders as needed
];

const LeadersBoard = () => {
  const [leaders, setLeaders] = useState([]);

  useEffect(() => {
    // Simulate an API call by using dummy data
    const fetchLeaders = async () => {
      try {
        // Add number 1 to the length of dummy data to each data in the `leaders` array
        const leadersWithNumber = dummyLeaders.map((leader, index) => {
          const number = index + 1;
          return {
            ...leader,
            number: numbro(number).format("0"),
          };
        });

        setLeaders(leadersWithNumber);
      } catch (error) {
        console.error("Error fetching leaderboard:", error);
      }
    };

    fetchLeaders();
  }, []);

  return (
    <div className="">
      {/* {
      
       <div className="max-w-[1200px] mx-auto bg-[#87CEEB] rounded-xl shadow-xl  px-8 md:px-20 pb-[200px]">
        <table className="table-fixed w-full border-separate border-spacing-y-10">
          <thead className="subscribe text-white">
            <tr>
              <th className="py-5 pl-5 sm:pl-10 text-left">TOP LEADERS</th>
              <th className="py-5 text-right pr-5 sm:pr-10">CHALLENGE POINTS</th>
            </tr>
          </thead>
          <tbody>
            {leaders.map((leader, index) => (
              <tr key={index}>
                <td>
                  <div className="flex items-center gap-5 sm:gap-10">
                    {index + 1}
                    <div className="flex h-[60px] sm:h-[80px] w-[60px] sm:w-[80px] rounded-full relative border-2 border-white/20">
                    
                      <div className="absolute bottom-0 right-0 h-[20px] sm:h-[30px] w-[20px] sm:w-[30px] rounded-full border-2 border-[#0F0F0F] subscribe flex justify-center items-center z-10">
                        <img src="/svgs/award.svg" alt="" />
                      </div>
                    </div>

                    {leader.username}
                  </div>
                </td>
                <td className="text-right">
                  <div className="flex justify-end">
                    <div
                      className={`flex gap-1 justify-center items-center subscribe rounded-[50px] px-5 py-2 ml-auto max-w-[224px] `}
                    >
                      <img src="/svgs/ticket.svg" alt="" />
                      <div className="text-sm sm:text-base text-white">
                        {numbro(leader.challenge).format({ thousandSeparated: true })}
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      } */}

      <h1 className="text-6xl text-black font-black text-center">COMING SOON</h1>
     
    </div>
  );
};

export default LeadersBoard;
