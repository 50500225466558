import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react'
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline'

const faqs = [
  {
    question: "What is FINENG, and how does it work?",
    answer:
      "FINENG is a cutting-edge financial engineering platform that combines Blockchain, Artificial Intelligence (AI), and cryptocurrency to empower users with the tools and knowledge they need to make informed investment decisions. The platform offers services like the monetization of financial instruments, risk management, and investment education across various sectors. Users can access these services to grow their wealth, manage risks, and diversify their portfolios.",
  },
  {
    question: "Who can benefit from using FINENG?",
    answer:
      "FINENG is designed for everyone, from seasoned investors to those just starting their financial journey. Whether you're an individual looking to grow your wealth, an institution aiming to integrate blockchain technology, or a social enterprise seeking to make a global impact, FINENG provides the tools, resources, and education needed to succeed in today's financial landscape.",
  },
  {
    question: "How does FINENG ensure the security of my investments?",
    answer:
      "FINENG employs advanced blockchain technology and AI-driven solutions to ensure the security and transparency of all transactions on the platform. By leveraging decentralized finance (DeFi) protocols and robust encryption, FINENG minimizes the risk of fraud and unauthorized access, providing users with a secure environment for managing their investments.",
  },

  {
    question: "What types of investments can I make on the FINENG platform?",
    answer:
      "On FINENG, you can diversify your investments across various sectors, including real estate, sustainable energy, technology, agriculture, and more. The platform also offers opportunities in decentralized finance (DeFi), allowing users to participate in staking, lending, and other blockchain-based financial services. Additionally, FINENG supports investments in socially responsible projects that contribute to global development.",
  },
  {
    question: "How does FINENG integrate AI into its financial services?",
    answer:
      "FINENG integrates AI to provide users with personalized financial advice, predictive analytics, and risk management tools. The platform's AI algorithms analyze market trends, historical data, and user preferences to deliver insights that help users make informed investment decisions. This AI-driven approach ensures that users can optimize their portfolios and achieve their financial goals.",
  },
  // More questions...
]

export default function Faq() {
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:px-8 lg:py-40">
        <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
          <h2 className="text-3xl font-bold tracking-tight text-gray-950  sm:text-4xl">Frequently asked questions</h2>
          <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
            {faqs.map((faq) => (
              <Disclosure key={faq.question} as="div" className="pt-6">
                <dt>
                  <DisclosureButton className="group flex w-full items-start justify-between text-left text-gray-900">
                    <span className="text-base font-semibold leading-7">{faq.question}</span>
                    <span className="ml-6 flex h-7 items-center">
                      <PlusSmallIcon aria-hidden="true" className="h-6 w-6 group-data-[open]:hidden" />
                      <MinusSmallIcon aria-hidden="true" className="h-6 w-6 [.group:not([data-open])_&]:hidden" />
                    </span>
                  </DisclosureButton>
                </dt>
                <DisclosurePanel as="dd" className="mt-2 pr-12">
                  <p className="text-base leading-7 text-gray-600">{faq.answer}</p>
                </DisclosurePanel>
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}
