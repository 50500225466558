import { useState } from 'react'
import { Dialog, DialogPanel } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom'

const navigation = [
    { name: 'Home', href: '/' },
    { name: 'Resources', href: 'https://www.youtube.com/@financialengineering2024' },
    { name: 'Tokenomics', href: '/tokenomics' },
    { name: 'Whitepaper', href: '/whitepaper.pdf' },
    { name: 'Roadmap', href: '/roadmap' },
  ]
 

export default function Navbar() {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  return (
    <header className="absolute inset-x-0 top-0 z-50">
    <nav aria-label="Global" className="flex items-center justify-between p-6 lg:px-8">
      <div className="flex lg:flex-1">
        <Link to="/" className="-m-1.5 p-1.5 inline-flex">
          <span className="sr-only">Financial Engineering</span>
          <img
            alt=""
            src="/images/logo.png"
            className="h-auto w-14"
          />
         <h1 className=" text-3xl font-bold mt-2 text-blue-500">Financial Engineering On Blockchain</h1>
        </Link>
       
      </div>
      <div className="flex lg:hidden">
        <button
          type="button"
          onClick={() => setMobileMenuOpen(true)}
          className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-900"
        >
          <span className="sr-only">Open main menu</span>
          <Bars3Icon aria-hidden="true" className="h-10 w-10" />
        </button>
      </div>
    
      <div className="hidden lg:flex lg:flex-1 lg:gap-x-12 lg:justify-center">
      {navigation.map((item) => (
          <a key={item.name} href={item.href} className="text-[16px] font-semibold leading-6 ">
            {item.name}
          </a>
        ))}
      </div>
    </nav>
    <Dialog open={mobileMenuOpen} onClose={setMobileMenuOpen} className="lg:hidden">
      <div className="fixed inset-0 z-50" />
      <DialogPanel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-gray-50 px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-white/10">
        <div className="flex items-center justify-between">
          <Link to="/" className="-m-1.5 p-1.5 inline-flex">
          <span className="sr-only">Financial Engineering</span>
          <img
            alt=""
            src="/images/logo.png"
            className="h-auto w-14"
          />
        <h1 className="text-gray-950 text-2xl font-bold mt-2">Financial Engineering On Blockchain</h1>
        </Link>
        
          <button
            type="button"
            onClick={() => setMobileMenuOpen(false)}
            className="-m-2.5 rounded-md p-2.5 text-gray-400"
          >
            <span className="sr-only">Close menu</span>
            <XMarkIcon aria-hidden="true" className="h-10 w-10" />
          </button>
        </div>
        <div className="mt-6 flow-root">
          <div className="-my-6 divide-y divide-gray-500/25">
            <div className="space-y-2 py-6">
              {navigation.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7  hover:bg-gray-800"
                >
                  {item.name}
                </a>
              ))}
            </div>
           
          </div>
        </div>
      </DialogPanel>
    </Dialog>
  </header>
  );
}
