import { ArrowRightCircleIcon } from "@heroicons/react/24/outline"
import { Link } from "react-router-dom"

const stats = [
    { label: 'Transactions every 24 hours', value: '44 million' },
    { label: 'Assets under holding', value: '$119 trillion' },
    { label: 'New users annually', value: '46,000' },
  ]
  
  export default function Purpose() {
    return (
      <div className=" bg-[#121212]py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
            <h2 className="text-3xl font-bold tracking-tight text-gray-950 sm:text-4xl">The Future of Finacial
                <br/>
                 Education</h2>
            <div className="mt-6 flex flex-col gap-x-8 gap-y-20 lg:flex-row">
              <div className="lg:w-full lg:max-w-2xl lg:flex-auto">
               
              
              </div>
              <div className="lg:flex lg:flex-auto lg:justify-center">
                <dl className="pace-y-8 ">
                <div className="grid gap-5 row-gap-5 mb-8 lg:grid-cols-2 sm:grid-cols-2">
                <div className="flex flex-col justify-between p-5   border subscribe rounded-xl shadow-lg">
          <div>
          
            
            <h2 className="font-bold leading-5 mt-10 mb-3 text-2xl  text-gray-50">Monetization Of Financial Institution Instruments</h2>
            <p className="mb-3  text-gray-50">
            Learn how to effectively monetize foreign financial instruments such as Bank Guarantee (BG) Sovereign Guarantee (SG) Safe Keeping Receipts (SKR) Gamestones like Gold, Silver, Bronze, Turmaline, Diamond, Sapphire, Platinum, Stock etc.. to unlock their value.

            </p>
            <a
            href="/"
            aria-label=""
            className="inline-flex items-center font-semibold transition-colors duration-200 text-deep-purple-accent-400 hover:text-deep-purple-800"
          >
          
          </a>
          </div>
         
        </div>
        <div className="flex flex-col justify-between p-5  border  subscribe rounded-xl shadow-lg">
          <div>
           
            
            <h2 className="font-bold leading-5 mt-10 mb-3 text-2xl text-gray-50">Investment Decision-Making</h2>
            <p className="mb-3  text-gray-50">
            Learn how to make sound investment decisions based on comprehensive financial analysis.
            </p>
            <a
            href="/"
            aria-label=""
            className="inline-flex items-center font-semibold transition-colors duration-200 text-deep-purple-accent-400 hover:text-deep-purple-800"
          >
          
          </a>
          </div>
         
        </div>
        <div className="flex flex-col justify-between p-5  border  subscribe rounded-xl shadow-lg">
          <div>
           
            <h2 className="font-bold leading-5 mt-10 mb-3 text-gray-50 text-2xl">Managing Sovereign Guarantee (SG)</h2>
            <p className="mb-3  text-gray-50">
            Gain insights into managing SGs to protect your investments and mitigate risks.


            </p>
            <a
            href="/"
            aria-label=""
            className="inline-flex items-center font-semibold transition-colors duration-200 text-deep-purple-accent-400 hover:text-deep-purple-800"
          >
           
          </a>
          </div>
         
        </div>
        <div className="flex flex-col justify-between p-5 border   subscribe rounded-xl shadow-lg">
          <div>
           
            <h2 className="font-bold leading-5 mt-10 mb-3 text-gray-50 text-2xl">Risk Calculation and Mitigation</h2>
            <p className="mb-3  text-gray-50">
            Understand how to calculate and avoid risks associated with market fluctuations, engaging strategies such as arbitrage Pricing theories (APT) in real world to ensuring stable returns and security on investments.

            </p>
            <a
            href="/"
            aria-label=""
            className="inline-flex items-center font-semibold transition-colors duration-200 text-deep-purple-accent-400 hover:text-deep-purple-800"
          >
           
          </a>
          </div>
         
        </div>
    
       
      </div>
                </dl>
              </div>
            </div>
          </div>
        </div>

        <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <section aria-labelledby="comfort-heading" className="mx-auto max-w-7xl mb-10">
          <div className="relative overflow-hidden rounded-lg">
            <div className="relative  bg-[#87CEEB] shadow-2xl rounded-3xl  px-6 py-24 sm:px-12 sm:py-20 ">
              <div className="max-w-4xl">
              <Link
                    href="#"
                    className="text-sm font-normal uppercase bg-gradient-to-r  from-orange-600 to-blue-500  px-4 py-2 rounded-full leading-6 text-white"
                  >
                    Case Study
                  </Link>
                <h2 id="comfort-heading" className="max-w-2xl mt-4 text-3xl font-bold tracking-tight text-gray-950 sm:text-4xl">
                Sustainable Investments
                </h2>
                <p className="max mt-3 text-xl text-gray-950">
                we believe in the power of sustainable investments. By leveraging blockchain technology, we offer secure and transparent investment opportunities in green energy, sustainable agriculture, and other sectors that contribute to a better tomorrow. Together, we can build a future that balances profit with purpose.
                </p>
               

              </div>
            </div>
          </div>
        </section>
      <div className="grid gap-4 row-gap-5 sm:grid-cols-2 lg:grid-cols-3">
        <div className="flex flex-col justify-between p-5 bg-[#87CEEB] rounded shadow-sm">
          <div>
            <Link
                    href="#"
                    className="text-sm font-normal uppercase bg-gradient-to-r  from-orange-600 to-blue-500  px-4 py-2 rounded-full leading-6 text-white"
                  >
                    Case Study
                  </Link>
            
            <h2 className="font-bold leading-5 mt-10 mb-3 text-2l text-gray-950">Agriculture and Aquaculture</h2>
            <p className="mb-3  text-gray-950">
            Learn about profitable ventures in farming, fisheries, sea-foods and related industries.

            </p>
            <a
            href="/"
            aria-label=""
            className="inline-flex items-center font-semibold transition-colors duration-200 text-deep-purple-accent-400 hover:text-deep-purple-800"
          >
           <ArrowRightCircleIcon className="w-10 h-auto"/>
          </a>
          </div>
         
        </div>
        <div className="flex flex-col justify-between p-5 bg-[#87CEEB] rounded shadow-sm">
          <div>
            <Link
                    href="#"
                    className="text-sm font-normal uppercase bg-gradient-to-r  from-orange-600 to-blue-500  px-4 py-2 rounded-full leading-6 text-white"
                  >
                    Case Study
                  </Link>
            
            <h2 className="font-bold text-gray-950 leading-5 mt-10 mb-3 text-2xl">Transportation and Energy</h2>
            <p className="mb-3  text-gray-950">
            Discover the potential in investing in transportation industry, energy sector and public infrastructure projects.

            </p>
            <a
            href="/"
            aria-label=""
            className="inline-flex items-center font-semibold transition-colors duration-200 text-deep-purple-accent-400 hover:text-deep-purple-800"
          >
           <ArrowRightCircleIcon className="w-10 h-auto"/>
          </a>
          </div>
         
        </div>
       
        <div className="flex flex-col justify-between p-5 bg-[#87CEEB] rounded shadow-sm">
          <div>
            <Link
                    href="#"
                    className="text-sm font-normal uppercase bg-gradient-to-r  from-orange-600 to-blue-500  px-4 py-2 rounded-full leading-6 text-white"
                  >
                    Case Study 
                  </Link>
            
            <h2 className="font-bold text-gray-950 leading-5 mt-10 mb-3 text-2xl">Computer Science and Technology</h2>
            <p className="mb-3  text-gray-950">
            Stay ahead by investing in the latest technological advancements and innovations.

            </p>
            <a
            href="/"
            aria-label=""
            className="inline-flex items-center font-semibold transition-colors duration-200 text-deep-purple-accent-400 hover:text-deep-purple-800"
          >
           <ArrowRightCircleIcon className="w-10 h-auto"/>
          </a>
          </div>
         
        </div>
      </div>
    </div>
      </div>
    )
  }
  